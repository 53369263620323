console.log("JS OK - 004");

// HACK IE11
// https://github.com/miguelcobain/ember-paper/issues/1058
// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
  console.log('navigateur ancien !!');
  document.querySelector("html").classList.add('no-js');
}

/////////////////////////////
// LAZYLOAD IMAGE : réglages supplémentaires
// https://github.com/aFarkas/lazysizes
lazySizesConfig.preloadAfterLoad = true;

/////////////////////////////
// MENU MOBILE

  var menuHeader = document.querySelector("#header");
  var btnMobile = menuHeader.querySelector("#btnMenu");
  var iconeMobile = menuHeader.querySelector("#nav-toggle");
  var navMobile = menuHeader.querySelector("#nav");
  var pageHTML = document.querySelector("html");

    //Ouvrir/fermer le menu
    btnMobile.addEventListener("click", function() {
      // console.log("click menu");
      iconeMobile.classList.toggle("active");
      navMobile.classList.toggle("open");
      pageHTML.classList.toggle("noScroll");
      menuHeader.classList.toggle("navOpen");
    });

    // Fermer le menu au clic sur un lien
    var lienMenus = menuHeader.querySelectorAll("li a");

    lienMenus.forEach(function(lienMenu){
        lienMenu.addEventListener("click", function(){
            iconeMobile.classList.remove("active");
            navMobile.classList.remove("open");          
            pageHTML.classList.remove("noScroll");
            menuHeader.classList.remove("navOpen");
        })
    });


/////////////////////////////
// MENU : CACHER AU SCROLL DOWN
// https://codingreflections.com/hide-header-on-scroll-down/

  var doc = document.documentElement;
  var w = window;

  var prevScroll = w.scrollY || doc.scrollTop;
  var curScroll;
  var direction = 0;
  var prevDirection = 0;


  var hauteurCover = document.querySelector(".pageCover").offsetHeight;
  var hauteurCoverSeule = document.querySelector(".pageCover").offsetHeight;


  var checkScroll = function() {

    /*
    ** Find the direction of scroll
    ** 0 - initial, 1 - up, 2 - down
    */

    curScroll = w.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) { 
      //scrolled DOWN
      direction = 2;
    }
    else if (curScroll < prevScroll) { 
      //scrolled UP
      direction = 1;
    }

    if (direction !== prevDirection) {
      toggleHeader(direction, curScroll);
    }


    // MENU FLOTTANT OU NORMAL
    if (curScroll < hauteurCover) { 
            menuHeader.classList.remove("MenuFlottant");
    }else if (curScroll > hauteurCover){
            menuHeader.classList.add("MenuFlottant");
    }


    prevScroll = curScroll;

  };

  var toggleHeader = function(direction, curScroll) {
    if (direction === 2 && curScroll > hauteurCover) { 
      // console.log('cas 2 - menu caché');
      //hauteurCover = the height of your header
      header.classList.add("hide");
      prevDirection = direction;
    }
    else if (direction === 1) {
      // console.log('cas 1 - menu visible');
      header.classList.remove("hide");
      prevDirection = direction;
    }
  };

  window.addEventListener("scroll", checkScroll);

/////////////////////////////
// MENU : EMPECHER SA DISPARITION AU CLIC ET SCROLL BAS 

// SI MOBILE : detection via CSS : btnMobile pas affiché
// https://davidwalsh.name/offsetheight-visibility
if(btnMobile.offsetHeight === 0){

  // Maintenir visible le menu
  menuHeader.addEventListener('mouseenter', function(){
      window.removeEventListener("scroll", checkScroll);
  });
  menuHeader.addEventListener('mouseleave', function(){
     window.addEventListener("scroll", checkScroll);
  });
}

/////////////////////////////
//AU CLIC : scroll animé
//https://gomakethings.com/how-to-scroll-an-element-into-view-with-vanilla-js/
var zoneClic = document.querySelector(".pageCover");
var arriveeScroll = document.querySelector(".pageContent");
zoneClic.addEventListener("click", function(){

  // arriveeScroll.scrollIntoView();

  // Polyfill pour safari
  // http://iamdustan.com/smoothscroll/
  window.scroll({ top: hauteurCoverSeule, left: 0, behavior: 'smooth' });

});



/////////////////////////////
// DIAPORAMA SWIPER.JS
// https://swiperjs.com/get-started

var blocDiaporama = document.querySelectorAll(".bloc-diaporama");
blocDiaporama.forEach(function(blocDiapo){

var diapo = blocDiapo.querySelector('.swiper-container');

const swiper = new Swiper(diapo, {

  // Optional parameters
  loop: true,
  // cssMode: true, 
  
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.clicNext',
    prevEl: '.clicPrev',
  },

});

}); //END FOREACH


/////////////////////////////
// GESTION DIMENSION DE LA FENÊTRE
var intFrameWidth, intFrameHeight;
function reportWindowSize(){
  intFrameWidth = window.innerWidth;
  intFrameHeight = window.innerHeight;  
  //console.log("largeur fenêtre : " + intFrameWidth + " / hauteur fenêtre :" + intFrameHeight);
}
reportWindowSize();
window.addEventListener('resize', reportWindowSize);


/////////////////////////////
// SURVOL DES REFERENCES
var itemRealisations = document.querySelectorAll(".itemRealisation");
var listeRealisation = document.querySelectorAll(".listeRealisation");
//Gestion du survol pour chaque références
itemRealisations.forEach(function(item){


  var imageRealisation = item.querySelector(".imgRealisation");

  item.addEventListener("mouseenter", function(e){
    // imageRealisation.classList.toggle("imgRealInvisible");
    imageRealisation.classList.toggle("imgRealVisible");
  });

  item.addEventListener("mousemove", function(e){

    //INFOS SUR LES COORDONNEES
    // https://developer.mozilla.org/en-US/docs/Web/CSS/CSSOM_View/Coordinate_systems
 
    //OFFSET X ou Y : position de la souris par rapport à l'élément sélectionné
    // console.log("offset x: " + e.offsetX + " / offset y: " + e.offsetY);

    //CLIENT X ou Y : position de la souris par rapport à la zone visible
    // console.log("client x: " + e.clientX + " / client y: " + e.clientY);

    //PAGE X ou Y : position de la souris par rapport à la page entière
    //console.log("page x: " + e.pageX + " / page y: " + e.pageY);


    // Calcul position x de l'image
    if (e.pageX < (intFrameWidth - (imageRealisation.offsetWidth + 80))) {
      positionX = e.pageX + 20;
      // console.log("casX 1 : " + (e.pageX < (intFrameWidth / 2)) );
    }else{
      positionX = (e.pageX - imageRealisation.offsetWidth) - 20;
      // console.log("casX 2 : " + (e.pageX < (intFrameWidth / 2)) );
      // console.log("page x: " + e.pageX + " - imageRealisation.offsetWidth : " + imageRealisation.offsetWidth + " = positionX: " + positionX );
    }

    // Calcul position Y de l'image
    var posItem = item.getBoundingClientRect();
    var posItemX = posItem.left;
    var posItemY = posItem.top;

    if (posItemY < (intFrameHeight / 2)) {
      positionY = e.pageY + 20;
      //console.log("casY 1 : " + (posItemY < (intFrameHeight - (imageRealisation.offsetHeight + 40))) );
    }else{
      //console.log("casY 2 : " + (posItemY < (intFrameHeight - (imageRealisation.offsetHeight + 40))) );
      positionY = (e.pageY - imageRealisation.offsetHeight) - 20;
      //console.log("item.clientY : " + posItemY);
      //console.log("page Y: " + e.pageY + " - imageRealisation.offsetHeight : " + imageRealisation.offsetHeight + " = positionY: " + positionY );
    }
  
    //Attribution position X et Y
    imageRealisation.style.left = positionX + "px";
    imageRealisation.style.top = positionY + "px";



    //console.log("pos left : " + imageRealisation.style.left + " / pos top : " + imageRealisation.style.top);
  });

  item.addEventListener("mouseleave", function(e){
    //console.log("out");
    // imageRealisation.classList.toggle("imgRealInvisible");
    imageRealisation.classList.toggle("imgRealVisible");
  });
});
